<template>
  <section class="dtc-main-section mt-4" id="">
    <header
      style="font-size: 20px; display: grid; place-items: center;font-family:DFKai-sb;border-bottom: 1px solid black;height:50px"
    >
      <div style="border:0px">
        {{ school }}學校{{ year }}學年度第{{
          term
        }}學期「原住民族及離島地區醫事人員養成計畫」教學設備費採購品項目表(申請用)
      </div>
    </header>
    <header class="dtc-grid-7">
      <div style="grid-column: 1/-1;text-align:left;padding-left:10px">
        可用預算額度:795,000
      </div>
    </header>
    <header class="dtc-grid-7">
      <div v-for="item in names" :key="item">{{ item }}</div>
    </header>

    <main class="dtc-grid-7" v-for="(item, i) in 9" :key="i">
      <div>{{ i + 1 }}</div>
      <div>羅技GROUP專用擴展麥克風</div>
      <div>2</div>
      <div>14,500</div>
      <div>29,000</div>
      <div>養成計畫</div>
      <div>遠距教學或會議使用</div>
    </main>
    <main class="dtc-grid-7">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div>261,000</div>
      <div></div>
      <div></div>
    </main>

    <footer>
      <div>【備註】</div>
      <div>
        1.
        醫學系及牙醫學系公費生每名每年新台幣3萬元，其他學系公費生每名每年新台幣1萬5,000元。
      </div>
      <div>
        2.本案補助設備定義為金額一萬元以上且使用年限，在兩年以上，依規定應編製財產增加單。
      </div>
      <div>
        3.設備採購金額或品項倘有變更，應於
        <span style="color:red;"
          >每年11月底前函報本部進行變更，並以一次為原則。</span
        >
      </div>
      <div>
        4.申請金額係為本學期因執行本計畫所申請之教學設備補助經費，非使用本計畫經費者，無須採計。
      </div>
      <div>
        5.倘有申請金額與總補助額度有差異或是有不同經費來源(如經費分攤等)，致經費有所差異，請於備註欄位處說明，以利核對。
      </div>
    </footer>
  </section>
</template>

<script>
const names = [
  "編號",
  "設備名稱",
  "數量",
  "單價",
  "合計(總價)",
  "經費來源",
  "用途說明",
];
import queryString from "query-string";

export default {
  name: "totalSpend15",
  data() {
    return {
      year: 108,
      names,
      term: 1,
      school: "中國醫藥大學",
      amount: 0,
      chineseAmount: "",
      map: {},
      allListItems: [],
      recommend: "",
      createName: "",
    };
  },
  methods: {
    async getData() {
      const {
        schoolcode: SchoolCode = "0003",
        byear: Byear = "109",
        term = 1,
        category: Category = "S",
      } = queryString.parse(location.href.split("?")[1]);
      this.term = term; // == 1 ? '上' : '下';
      this.year = Byear;
      let schoolArr = await window.axios.get("School/GetAll");
      this.school = schoolArr.find(
        (s) => s.SchoolCode == SchoolCode
      ).SchoolName;
      const url = `RecordAll/Get?byear=${Byear}&term=${term}&schoolcode=${SchoolCode}&category=${Category}`;
      try {
        const map = await window.axios.get(url);
        if (map.length) {
          let items = [];
          this.map = map[0];
          items = map[0].nurse_recordall;
          items = items.map((s) => {
            if (s.Grade == 1) {
              s.GradeName = "一";
              return s;
            } else if (s.Grade == 2) {
              s.GradeName = "二";
              return s;
            } else if (s.Grade == 3) {
              s.GradeName = "三";
              return s;
            } else if (s.Grade == 4) {
              s.GradeName = "四";
              return s;
            }
          });
          items.sort(function(a, b) {
            return a.Grade - b.Grade;
          });
          this.recommend = map[0].Recommend;
          this.createName = map[0].CreateName;
          this.allListItems = items;
        }
      } catch (e) {
        const str = e + " API: " + url;
        this.$root.$emit("dtc-server-error", str);
      }
    },
  },
  async mounted() {
    const {
      schoolcode: SchoolCode = "0003",
      byear: Byear = "109",
      term = "1",
      category: Category = "S",
    } = queryString.parse(location.href.split("?")[1]);
    this.term = +term;
    Byear ? (this.bYear = Byear) : (this.bYear = "109");

    //for check is same school-prevent see other school
    const { schoolcode: schoolNow } = queryString.parse(
      location.href.split("?")[1]
    );

    const loginHealthCode = sessionStorage.getItem("darcok").replace(/\"/g, "");

    const stuChoolArr = JSON.parse(sessionStorage.getItem("stuChool"));
    const roleOtalArr = JSON.parse(sessionStorage.getItem("roleOtal"));
    const isSchoolPerson = stuChoolArr.includes(loginHealthCode) ? true : false;

    const isAdminPerson = roleOtalArr.includes(loginHealthCode) ? true : false;

    if (!isSchoolPerson && !isAdminPerson) {
      return;
    }

    if (isSchoolPerson) {
      if (loginHealthCode != schoolNow) {
        return;
      }
    }
    //check Can see and then get data
    this.getData();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.dtc-main-section {
  width: 1140px;
  margin: 0 auto;
}
.dtc-header {
  border: 1px solid black;
  height: 80px;
  font-size: 24px;
  text-align: center;
}
.h2 {
  border: 1px solid black;
  border-top: none;
  height: 40px;
  font-size: 18px;
  text-align: left;
  line-height: 40px;
  padding-left: 5px;
  font-weight: bold;
  margin-bottom: 0;
}

.dtc-grid-7 {
  display: grid;
  border: 1px solid black;
  min-height: 30px;
  border-top: none;
  grid-template-columns: 90px 250px 90px 120px 120px 130px 1fr;
  > div {
    border-right: 1px solid black;
    height: 100%;
    font-size: 16px;
    text-align: center;
    line-height: 30px;
  }
  > div:last-child {
    border-right: none;
  }
}

footer {
  border: 1px solid black;
  border-top: none;
  min-height: 140px;
  font-size: 13px;
  text-align: left;
  padding-left: 2px;
  padding-top: 10px;
  div {
    font-size: 16px;
  }
}
</style>
